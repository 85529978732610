










































































  import api from '@/api';
  import ChangePasswordModal from '@/components/ChangePasswordModal/ChangePasswordModal.vue';
  import GroupSubscriptionRecoveryEmailModal from '@/components/GroupSubscription/GroupSubscriptionRecoveryEmailModal.vue';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';

  export default Vue.extend({
    name: 'AdditionalAccountsWelcomeModal',
    components: {
      QueryModal,
      ChangePasswordModal,
      GroupSubscriptionRecoveryEmailModal,
    },
    data() {
      return {
        isWelcomeModalClosable: false,
        isChangePasswordClosable: false,
        isRecoveryClosable: false,
        changingPassword: false,
        changePasswordValidationErrorMessages: {},
        isRecoveryEmailModalOpen: false,
        showCongratulationModal: false,
      };
    },
    computed: {
      ...mapGetters({
        user: 'authentication/user',
        keys: 'keys',
      }),
      changePasswordCurrentPasswordText(): string {
        return this.$gettext('Old password');
      },
      changePasswordNewPasswordText(): string {
        return this.$gettext('Create new password');
      },
      changePasswordRepeatPasswordText(): string {
        return this.$gettext('Confirm password');
      },
      getSkipText(): string {
        return this.$gettext('Skip');
      },
      userInputs(): string[] {
        return [this.user.display_name || '', this.user.email];
      },
    },
    methods: {
      ...mapActions(['setToastMessage', 'loadKeys']),
      async openChangePasswordModal() {
        this.isWelcomeModalClosable = true;
        await this.$nextTick();
        (this as any).$refs.welcomeModal.closeModal();
        (this as any).$refs.changePasswordModal.toggleModal();
      },
      changePassword({
        currentPassword,
        newPassword,
      }: {
        currentPassword: string;
        newPassword: string;
      }) {
        this.changingPassword = true;
        api.authentication
          .changePassword({ currentPassword, newPassword })
          .then(() => this.closeChangePasswordModal())
          .then(() => this.updatePgpPassphrase(currentPassword, newPassword))
          .then(() => {
            this.setToastMessage({
              message: this.$gettext('Password has been changed'),
            });
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.changePasswordValidationErrorMessages = {
                currentPassword: this.$gettext('Wrong old password.'),
              };
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not change your password'
                ),
              });
            }
          })
          .finally(() => {
            this.changingPassword = false;
          });
      },
      async updatePgpPassphrase(currentPassword: string, newPassword: string) {
        try {
          await this.loadKeys();
          const userPgp = this.keys.filter((key: PGPKey) => {
            return key.user_ids[0].address === this.user.email;
          });
          await api.keys.changePassphrase({
            fingerprint: userPgp[0].fingerprint,
            currentPassphrase: currentPassword,
            newPassphrase: newPassword,
          });
        } catch {
          this.setToastMessage({
            message: this.$gettext(
              'PGP passphrase update error. Please contact support.'
            ),
          });
        }
      },
      async closeChangePasswordModal() {
        this.isChangePasswordClosable = true;
        await this.$nextTick();
        (this as any).$refs.changePasswordModal.toggleModal();
        this.isRecoveryEmailModalOpen = true;
      },

      closeRecoveryModal() {
        this.isRecoveryEmailModalOpen = false;
        this.isRecoveryClosable = true;
        this.showCongratulationModal = true;
      },

      changePasswordResetValidation(event: boolean) {
        if (event) {
          this.isChangePasswordClosable = event;
          this.isRecoveryEmailModalOpen = event;
        }
        this.changePasswordValidationErrorMessages = {};
      },

      closeCongratulationModal() {
        (this as any).$refs.congratulationModal.closeModal();
        this.showCongratulationModal = false;
      },
    },
  });
